<template>
<section class="mt-3">
    <div class="container-lg">
        <div class="row">
        <div>
            <router-link :to="{ name: 'Home'}" class="btn btn-outline-primary border-0 mb-5"><i class="bi bi-arrow-left"></i> {{ translated.button.back}}</router-link>
            <h1 class="display-4">{{ translated.title}}</h1>
        </div>
        <p>{{ translated.description}}</p>
    </div>
    <div class="row">
           <table class="table table-striped">
            <thead>
            <tr>
              <th scope="col">{{ translated.table.dateCreated}}</th>
              <th scope="col">{{ translated.table.session}}</th>
              <th scope="col">{{ translated.table.numberOfUsers}}</th>
              <th scope="col">{{ translated.table.content}}</th>
              <th scope="col">{{ translated.table.classroom }}</th>
              <th scope="col">{{ translated.table.driveFolder}}</th>
              <th scope="col">{{ translated.table.license}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="session in sessions" :key="session.id">
              <td>{{new Date(session.createdAt.toDate()).toISOString().split('T')[0]}}</td>
              <td>
                <router-link :to="{ name: 'SessionDetails', params: { id: session.id}}">{{session.sessionName}} <i class="bi bi-box-arrow-in-right"></i></router-link>

              </td>
              <td>{{session.userNumber}}</td>    
              <td>{{session.demoName}}</td>
              <td><a :href="classroom.link" target="_blank" v-for="classroom in session.classrooms" :key="classroom">{{ classroom.name}}</a></td>
              <td><a :href="'https://drive.google.com/drive/folders/'+session.folder" target="_blank">{{session.sessionName}} Folder</a></td>
              <td><select class="form-select" id="userLicense" v-model="session.userLicense" @change="updateLicense(session.userName,session.userNumber,session.userLicense,session.id)">
                <option value="1010310008">Google Workspace for Education Plus</option>
                <option value="1010310009">Google Workspace for Education Plus (Staff)</option>
                <option value="1010370001">Google Workspace for Education: Teaching and Learning Upgrade</option>
                <option value="1010310002">Google Workspace for Education Plus - Legacy</option>
                <option value="none">Google Workspace for Education Fundamentals</option>
              </select>
              </td>
            </tr>
           
          </tbody>
          
          </table>
    </div>
    </div>
</section>
  
</template>

<script>
import { computed, ref } from '@vue/reactivity'
import axios from "axios";
import getCollection from '@/composables/getCollection'
import getSubCollection from '@/composables/getSubCollection'
// import DemoContentTable from '@/components/DemoContentTable.vue'
import getUser from '@/composables/getUser'
import getDoc from '@/composables/getDoc'
import translate from '@/composables/translate'
import { useStore } from 'vuex'
// firebase imports
import { db } from '@/firebase/config'
import { doc, addDoc, deleteDoc, updateDoc, collection, serverTimestamp } from 'firebase/firestore'
export default {
    setup(){
        const { user,userInfo } = getUser()
        const store = useStore()
        const lang = computed(() => store.state.lang)
        const { getLanguage, translated } = translate()
        getLanguage('manageDemos',lang)
        const languages = ref([])
        axios.get('/data/locale.json')
        .then((res) => {
        languages.value = res.data.languages
        })

        const { documents: sessions } = getCollection('trainings',
          ['createdBy','==', user.value.email],['createdAt','desc']
          )
          console.log('sessions',sessions)
          console.log(user.value.email)


          async function updateLicense(sessionName,numberUsers,skuId,sessionId) {
            axios.get('https://us-central1-demotool-e1a5e.cloudfunctions.net/updateLicense?sessionName='+sessionName+'&numberUsers='+numberUsers+'&skuId='+skuId)
            await setDoc(doc(db, 'users', sessionId), {
            userLicense: skuId
          },{ merge: true });
            }

        return { user, userInfo, sessions, translated, updateLicense }
    },

}
</script>
    
<style>
.form-check-input {
  transform: scale(1.4);
}
::-webkit-datetime-edit-second-field {
    background: white;
    color: transparent;
    margin-left: -3px;
    position: absolute;
    width: 1px;
  }
</style>