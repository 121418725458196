<template>
<section>
  <div class="container-lg">
    <form @submit.prevent="handleSubmit">
    <div class="row">
      
      <h1>Create demo content</h1>
    
      <div class="col-md-7">
      <label for="title" class="form-label">Title:</label>
      <div class="input-group mb-4">
              <input type="text" id="title" class="form-control" v-model="demo.title" required />
      </div>
      <label for="description" class="form-label">Description:</label>
      <div class="input-group mb-4">
              <textarea name="description" id="description" class="form-control"  rows="3" v-model="demo.description"></textarea>
      </div>
      </div>
      <div class="col-md-5">
        <div v-for="group in groups" :key="group">
                <h5>{{ group }}</h5>
                <div v-for="field in fields" :key="field.id">
                    <div :id="group.replace(/\s+/g, '-').toLowerCase()"  v-if="field.group === group">
                  <div class="form-check"  >
                      <check-box :fieldId="field.name" v-model:tags="demo.tags" :value="field.name" />
                  </div>
                  </div>
                </div>
            
              </div>
              
      </div>
      <div class="mb-4 text-left">
              <button type="submit" class="btn btn-secondary"><i class="bi bi-save"></i> Save</button>
      </div>
    
    </div>
    </form>
    <div v-if="demoId">
    <form @submit.prevent="addContent">
    <div class="row">
      <h2>Content</h2>
      
        <div class="col-md-3">
          <label for="fileName" class="form-label">Name:</label>
          <div class="input-group mb-3">
              <input type="text" id="fileName" class="form-control" v-model="fileName" required />
          </div>
        </div>
        <div class="col-md-3">
          <label for="fileId" class="form-label">File ID:</label>
          <div class="input-group mb-3">
              <input type="text" id="fileId" class="form-control" v-model="fileId" required />
          </div>
        </div>
        <div class="col-md-2">
          <label for="fileType" class="form-label">Type:</label>
          <div class="input-group mb-3">
              <select class="form-select" aria-label="Type" id="fileType" v-model="fileType">
                <option selected></option>
                <option value="document">Docs</option>
                <option value="spreadsheets">Sheets</option>
                <option value="presentation">Slides</option>
                <option value="forms">Forms</option>
                <option value="jam">Jamboard</option>
                <option value="classroom">Classroom</option>
                <option value="folder">Folder</option>
              </select>
          </div>
          </div>
          <div class="col-md-2">
          <label for="language" class="form-label">Language:</label>
          <div class="input-group mb-3">
              <select class="form-select" aria-label="Language" id="language" v-model="fileLanguage">
                <option value="en">English</option>
                <option v-for="lang in languages" :value="lang.code" :key="lang.code">{{ lang.locale }} | {{ lang.localeInLanguage.replaceAll('"','') }}</option>
              </select>
          </div>
        </div>
        <div class="col-md-2 align-self-center">
          <button type="submit" class="btn btn-secondary"><i class="bi bi-file-earmark-plus"></i> Add</button>
        </div>
        
      
    </div>
    </form>  
    <demo-content-table :content="files" v-if="files"/>
    </div>
  </div>
</section>
</template>

<script>
import { ref } from 'vue'
//import { computed } from '@vue/reactivity'
import DemoContentTable from '@/components/DemoContentTable.vue'
import getUser from '@/composables/getUser'
import getTags from '@/composables/getTags'
import Checkbox from '@/components/CheckBox.vue'
import getCollection from '@/composables/getCollection'
import getSubCollection from '@/composables/getSubCollection'
import { v4 as uuidv4 } from 'uuid';
// firebase imports
import { db } from '@/firebase/config'
import { addDoc, collection, serverTimestamp, doc, getDoc } from 'firebase/firestore'
export default {
  props: ['id'],
  setup(props) {
    // demo form refs
    const { user } = getUser()
    const demoId = ref(props.id)
    const demo = ref({
      title: '',
      description: '',
      tags: [],
      content: []
    })
    const error = ref(null)
    const load = async () => {
        const docRef = doc(db, 'demos', props.id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        demo.value = { ...docSnap.data(), id: props.id }
        error.value = null
        console.log("Document data:", demo.value);
    } else {
        // doc.data() will be undefined in this case
        error.value = 'that document does not exist'
        //console.log("No such document!");
    }
    }
    load()
    //const { documents: content } = getCollection('content')
    const { documents: fields } = getCollection('forms',
    ['form','==', 'filter']
    )
    const { documents: languages } = getCollection('localeCodes','','code')
    
    const { documents: files } = getSubCollection('demos',props.id,'content','','')
    
    //const { documents: tagsArray } = getTags('forms')
    const { documents: tags } = getTags('forms')
    

    // content form refs
    const fileName = ref('')
    const fileId = ref('')
    const fileType = ref('')
    const fileLanguage = ref('')


    const groups = ['Workspace Edition','Level']

    // const files = computed(() => {
    //   return content.value.filter(c => demo.value.content.includes(c.primaryKey))
    // })

    const handleSubmit = async () => {
      const colRef = collection(db, 'demos')

      const res = await addDoc(colRef, {
        title: demo.value.title,
        description: demo.value.description,
        tags: demo.value.tags,
        createdAt: serverTimestamp(),
        lastModified: serverTimestamp(),
        createdBy: user.value.email
      })
      console.log(res)
      demoId.value = res.id
    }

    const addContent = async () => {
      const colRef = collection(db, 'demos', props.id, 'content')

      const ref = await addDoc(colRef, {
        name: fileName.value,
        fileType: fileType.value,
        fileId: fileId.value,
        language: fileLanguage.value,
        createdAt: serverTimestamp(),
        createdBy: user.value.email,
        demo: demoId.value,
        primaryKey: uuidv4()
      })
      console.log(ref)
    }
        
      

    return { demo, handleSubmit, getTags, groups, fields, tags, files, languages, fileName, fileId, fileType, fileLanguage, addContent, demoId}
  },
components: {
    "check-box": Checkbox,
    "demo-content-table": DemoContentTable,
  },
}
</script>

<style>

</style>