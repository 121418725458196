import { ref } from 'vue'

// firebase imports
import { db } from '../firebase/config'
import { doc, getDoc } from "firebase/firestore";

const getDocument = (collection, id) => {

    const document = ref(null)
    const error = ref(null)
    const load = async () => {
        const docRef = doc(db, collection, id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        document.value = { ...docSnap.data(), id: id }
        error.value = null
        console.log("Document data:", document.value);
    } else {
        // doc.data() will be undefined in this case
        error.value = 'that document does not exist'
        //console.log("No such document!");
    }
    }
    
    return { error, document, load }
}

export default getDocument