import { ref, watchEffect } from 'vue'

// firebase imports
import { db } from '../firebase/config'
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore'

const getCollection = (c, q, o) => {
    const documents = ref(null)

    // collection reference
    let colRef = collection(db, c)

    if (q) {
        colRef = query(colRef, where(...q))
    }

    if (o) {
        colRef = query(colRef, orderBy(...o))
    }
    
    const unsub = onSnapshot(colRef, snapshot => {
        let results = []
        snapshot.docs.forEach( doc => {
          results.push({ ...doc.data(), id: doc.id })
        })

        // update values
        documents.value = results

    })

    watchEffect((onInvalidate) => {
        onInvalidate(() => unsub())
    })

    return { documents }
}

export default getCollection