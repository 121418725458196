<template>
  <section class=" mt-3">
    <div class="px-4 py-5 my-5 text-center">
    <h1 class="display-5">{{ props.errorType }}</h1>
    <div class="col-lg-6 mx-auto">
      <p class="lead mb-4">{{ props.errorMessage }}</p>
      
    </div>
  </div>
  </section>
</template>

<script>
export default {
    props: {
        errorType: String,
        errorMessage: String
    },
    setup(props) {

        return { props }
    }

}
</script>

<style>

</style>