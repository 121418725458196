<template>
  <section>
  <div class="container-lg">
    <div class="row">
      <div class="d-flex col-auto">
          <router-link :to="{ name: 'Home' }" class="btn btn-outline-primary border-0 mb-3 mt-3"><i class="bi bi-arrow-left"></i> Back to home</router-link>
        </div>
        <h2 class="display-6">{{translated.title}}</h2>
        <p>{{ translated.text }}</p>
    </div>

    <div class="accordion" id="accordion">
  <div class="accordion-item" v-for="(item, index) in translated.accordionItems" :key="item.title">
    <h2 class="accordion-header" :id="index">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#flush-collapseOne'+index" aria-expanded="false" :aria-controls="'flush-collapseOne'+index">
        {{ item.title }}
      </button>
    </h2>
    <div :id="'flush-collapseOne'+index" class="accordion-collapse collapse show" :aria-labelledby="index" v-if="index == 0">
      <div class="accordion-body">
          <div v-dompurify-html="item.content"></div>
      </div>
    </div>
    <div :id="'flush-collapseOne'+index" class="accordion-collapse collapse" :aria-labelledby="index" v-if="index != 0">
      <div class="accordion-body">
          <div v-dompurify-html="item.content"></div>
      </div>
    </div>
  </div>
</div>
  </div>
</section>
</template>

<script>
import translate from '@/composables/translate'
import { useStore } from 'vuex'
import { computed } from '@vue/reactivity'
export default {
  setup(){
    const store = useStore()
    const lang = computed(() => store.state.lang)
    const { getLanguage, translated, forms } = translate()
    getLanguage('explore',lang)

    console.log(translated)

    return { translated }
      
  },

}

</script>

<style>

</style>