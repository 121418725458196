<template>
  <footer class="footer mt-auto py-3 bg-light">
  <div class="container">
      <div class="row">
          <div class="col">
          <span class="text-muted">© 2024 AppsEvents/Google</span>
      </div>
      <div class="col text-end">
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSexd_FA6GqGmZPHYrqwxyAS9JOD6-LV0WmT9_DybEVxkfS9CA/viewform">
            Feedback and Ideas <i class="bi bi-lightbulb"></i>
          </a>
      </div>
      </div>
  </div>
</footer>
</template>

<script>
export default {

}
</script>

<style>
footer {
  margin-top: auto;
  background-color: #222;
}
</style>