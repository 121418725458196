import { ref } from 'vue'
import getDoc from '@/composables/getDoc'

// firebase imports
import { auth } from '../firebase/config'
import { onAuthStateChanged  } from 'firebase/auth'

// refs
const user = ref(auth.currentUser)
const userInfo = ref('')

// auth changes
onAuthStateChanged(auth, async (_user) => {
    console.log('User state changed. Current user is', _user)
    try {
        userInfo.value = await getDoc('users',_user.uid) // gets user values from firestore
    }
    catch(e){console.log(e)}
    user.value = _user
})

const getUser = () => {
    return { user, userInfo }
}

export default getUser