<template>
  <div class="row" v-if="files">
          <table class="table table-striped">
            <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Title</th>
              <th scope="col">Languages</th>
              <th scope="col">Description</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="file in files" :key="file.id">
              <td><span @click="handleDelete(file.id)" v-if="props.user.admin"><i class="bi bi-x-circle"></i></span></td>
              <td>
                  <img :src="getImgUrl(file.fileType)" :alt="file.fileType" width="30"> {{ file.languageFileName[user.language] || file.languageFileName['en'] }}
                  </td>
              <td><span class="badge bg-secondary me-1" v-for="(value, key) in file.languageFileName" :key="key">{{ key }}</span> <i class="bi bi-plus-square-fill text-secondary"></i></td>    
              <td></td>
            </tr>
           
          </tbody>
          
          </table>
        </div>
</template>

<script>
//import { ref } from '@vue/reactivity'
import getSubCollection from '@/composables/getSubCollection'
// firebase imports
import { db } from '@/firebase/config'
import { deleteDoc, doc } from 'firebase/firestore'
export default {
    props: {
    id: {
      type: String,
      required: true,
    },
    user: {
      type: Object,
      required: true
    }
  },
    setup(props) {

      const { documents: files } = getSubCollection('demos',props.id,'content','','')

        //const files = ref(props.content)
        function getImgUrl(icon) {
    return require('../assets/icons/'+icon+'.png')
    }

    const handleDelete = (file) => {
      const docRef = doc(db, 'demos', props.id, 'content', file)

      deleteDoc(docRef)
    }

    return { getImgUrl, files, handleDelete, props }
    }

}
</script>

<style>

</style>