import { ref } from 'vue'

// firebase imports
import { auth, db } from '../firebase/config'
import { signInWithPopup, GoogleAuthProvider, signOut } from 'firebase/auth'
import { doc, setDoc, Timestamp } from 'firebase/firestore'; 
import router from '../router';


const error = ref(null)
const isPending = ref(false)
const login = async () => {
  error.value = null
  isPending.value = true

  const provider = new GoogleAuthProvider();

  provider.setCustomParameters({
    prompt: 'select_account'
  });
  

  try {
    const res = await signInWithPopup(auth, provider);
    if (!res) {
      router.push({ name: 'Error', query: {
        errorType: 'Error logging in',
        errorMessage: 'Could not login. Please ensure you login using your demogfe.com Google account.'} })
   
      throw new Error('Could not login')
    }
    const emailDomain = res.user.email.split('@')[1];

if (emailDomain !== 'demogfe.com') {
    signOut(auth)
    .then(() => {
      console.log('in router push sign out error')
        router.push({ 
            name: 'Error', 
            query: {
                errorType: `Oops! You've tried logging in with the wrong account.`,
                errorMessage: 'Please try again using your demogfe.com Google account.'
            } 
        });
    })
    .then(() => {
        console.log('errorPage');
    })
    .catch(() => {
        throw new Error('Invalid domain');
    });
}


    await setDoc(doc(db, 'users', res.user.uid), {
      email: res.user.email,
      fullname: res.user.displayName,
      photoUrl: res.user.photoURL,
      lastSignIn: Timestamp.fromDate(new Date(res.user.metadata.lastSignInTime)),
      creationTime: Timestamp.fromDate(new Date(res.user.metadata.creationTime)) 
    },{ merge: true });

    error.value = null
    isPending.value = false
    
    router.push({ name: 'Home'}).then(() => {
      window.location.reload()
    })
    
  }
  catch(err) {
    console.log(err.message)
    error.value = err.message
    isPending.value = false
  }
}

const uselogin = () => {
  return { error, login, isPending }
}

export default uselogin