<template>
<div>
<div class="d-flex flex-column min-vh-100">
  
  <Navbar />
  <!-- <transition name="route">
  <Banner />
  </transition> -->
  <div class="b-example-divider"></div>
  <router-view v-slot="{ Component }">
    <transition name="route" mode="out-in">
      <component :is="Component"></component>
    </transition>
  </router-view>
  <Footer />
  </div>
  
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue'
import Footer from './components/Footer.vue'
import Banner from './components/Banner.vue'

export default {
  components: { Navbar, Footer, Banner },
  setup() {

    
    
    
  },
}
</script>


<style>

.route-enter-from {
  opacity: 0;
  /* transform: translateX(100px); */
}
.route-enter-active {
  transition: all 0.3s ease-out; 
}
.route-leave-to {
  opacity: 0;
  /* transform: translateX(-100px); */
}
.route-leave-active {
  transition: all 0.3s ease-in; 
}



</style>