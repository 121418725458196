import { ref, watchEffect } from 'vue'

// firebase imports
import { db } from '../firebase/config'
import { collection, onSnapshot, query, where } from 'firebase/firestore'

const getCollection = (c, q, q2) => {
    const documents = ref()

    // collection reference
    let colRef = collection(db, c)

    if (q) {
        colRef = query(colRef, where(...q))
        if(q2){
            colRef = query(colRef, where(...q2))
        }
    }
    
    
    const unsub = onSnapshot(colRef, snapshot => {
        let results = []
        snapshot.docs.forEach( doc => {
          results.push( doc.data().name )
        })

        // update values
        documents.value = results

    })

    watchEffect((onInvalidate) => {
        onInvalidate(() => unsub())
    })

    return { documents }
}

export default getCollection