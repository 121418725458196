import axios from "axios";
import { ref } from "vue";
import getUser from '../composables/getUser'
import getDocument from '@/composables/getDocument'

const translated = ref('en')
const forms = ref('')
const partnerFilter = ref('')

const languages = ['en','es','ja','it','pt']

const getLanguage = async (page, l) => {
    let language
    let userLanguage
     const { user } = await getUser()
     if(user.value) {
     const { error, document, load } = getDocument('users',user.value.uid)
     await load()
     userLanguage = document.value.language 
     }
    
    if(user.value && userLanguage && languages.includes(userLanguage)){
        language = userLanguage
    }
    else {
        language = l.value
    }

    axios.get(`/data/localization/${language}.json`)
    .then((res) => {
        translated.value = res.data[page]
        forms.value = res.data['forms']
        partnerFilter.value = res.data['partnerFilter']
    })}
    

    const translate = () => {
        return { getLanguage, translated, forms, partnerFilter }
      }

      export default translate
