import { ref, watchEffect } from 'vue'

// firebase imports
import { db } from '../firebase/config'
import { doc, onSnapshot } from 'firebase/firestore'

const getDoc = (c, d) => {
    const document = ref(null)

    // collection reference
    let docRef = doc(db, c, d)
    
    const unsub = onSnapshot(docRef, snapshot => {
        document.value = { ...snapshot.data(), id: d }
    })

    watchEffect((onInvalidate) => {
        onInvalidate(() => unsub())
    })

    return { document }
}

export default getDoc