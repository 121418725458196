import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCVztayxWCSZPxgkyW8-OT2tQc1heB1KDs",
    authDomain: "demotool-e1a5e.firebaseapp.com",
    projectId: "demotool-e1a5e",
    storageBucket: "demotool-e1a5e.appspot.com",
    messagingSenderId: "336811170719",
    appId: "1:336811170719:web:95a2d3e8660f0783927baa",
    measurementId: "G-1BTF1HRRC9"
  };

  // init firebase
initializeApp(firebaseConfig)  

// init services
const db = getFirestore()
const auth = getAuth()

export { db, auth }