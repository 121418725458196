<!-- Maintenance.vue -->
<template>
  <div>
    <section id="intro" class="bg-light">
      <div class="px-4 py-5 my-5 text-center">
        <h1 class="display-4">Demoworkspace Maintenance</h1>
        <div class="mx-auto">
          <p class="lead mb-4">
            We’re making some changes to demoworkspaceedu.com to provide more
            features and improve the experience for all users.
          </p>
        </div>
      </div>
    </section>
    <section class="mt-5">
      <div class="container-lg">
        <h3 class="display-6">What's changing</h3>
        <ul>
          <li>
            <strong>A new domain:</strong> Demoworkspaceedu.com will be getting
            a new name to be more inclusive of both Workspace and ChromeOS.
          </li>
          <li>
            <strong>Additional demos:</strong> In September, we’ll be adding a
            new Security Center demo experience.
          </li>
          <li>
            <strong>Additional policies:</strong> We’ll also be adding some
            additional policies around sharing and collaboration to align with
            the best practices that we recommend for educational environments.
            With these policies in place, you’ll be able to show schools the
            power of Google Workspace for Education’s security and privacy tools
            and how they can configure their environments most securely and
            effectively.
          </li>
        </ul>

        <h3 class="display-6">What you need to do</h3>
        <ul>
          <li>
            <strong>Transfer any existing files:</strong> On August 30, 2023
            all existing demoworkspaceedu.com accounts will be suspended. If
            you have any important emails or files in your current
            demoworkspaceedu.com account, please share the data with another
            account (such as your school or business account), download the
            data, or use Google takeout to retrieve the data, prior to Aug 30. 
            If you missed this deadline you may temporarily 
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSe9QCjXEXaFU5gkvpwTxb7WNboEsUChH-a0RXSU2y27CmDXrQ/viewform" target="_blank">re-activate your account</a>
            and download your data.
          </li>
          <li>
            <strong>Sign up for a new account:</strong> On August 30, 2023,
            we’ll also send an email with a link to sign up for a new
            demoworkspaceedu.com account. Once you receive the email, please
            follow the instructions to create a new account to continue using
            the environment.
          </li>
        </ul>

        <h3 class="display-6">Important reminders:</h3>
        <ul>
          <li>
            When creating or holding customer meetings, sending emails, or
            sharing files with customers, please continue to use your own
            trainer, school, or partner/ business Workspace account (rather than
            your demoworkspaceedu.com account) for these types of activities.
          </li>
          <li>
            If you do share files externally using your demoworkspaceedu.com
            account, all link sharing settings will be automatically reset to
            “restricted” every 24 hours.
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>
  