<template>
  <div>
    <input
      @input="check()"
      type="checkbox"
      :checked="checked"
      :id="fieldId"
      v-bind="$attrs"
      class="form-check-input"
    />
    <label :for="fieldId" class="form-check-label">{{ fieldId }}</label>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  emits: ["update:tags"],
  props: {
    value: {
      type: String,
      required: true,
    },
    fieldId: {
      type: String,
      required: true,
    },
    tags: {
      type: Array,
      required: true,
    },
  },
  setup(props, context) {
    const checked = computed(() => props.tags.includes(props.value));

    function check() {
      let updatedTags = [...props.tags];
      if (this.checked) {
        updatedTags.splice(updatedTags.indexOf(props.value), 1);
      } else {
        updatedTags.push(props.value);
      }
      context.emit("update:tags", updatedTags);
    }

    return {
      check,
      checked,
    };
  },
};
</script>

<style>
</style>
