<template>
  <section id="register" class="mt-3">
    <div class="container-lg" v-if="form.submit != true">
      <div class="text-center">
        <h2>{{ translated.title }}</h2>
        <p class="lead">{{ translated.welcome }}</p>
      </div>
      <div class="row justify-content-center my-5">
        <div class="col-lg-6">
          <form @submit.prevent="handleSubmit">
            <label for="email" class="form-label"
              >{{ translated.form.email }}:</label
            >
            <div class="input-group mb-4">
              <span class="input-group-text">
                <i class="bi bi-envelope-fill text-secondary"></i>
              </span>
              <input
                type="email"
                id="email"
                class="form-control"
                v-model="form.email"
                required
                @input="handleGooglers"
              />
              <!-- tooltip -->
              <span class="input-group-text">
                <span
                  class="tt"
                  data-bs-placement="bottom"
                  title="Partners please use your company email. Trainers please use the email connected to your Activity App."
                >
                  <i class="bi bi-question-circle text-muted"></i>
                </span>
              </span>
            </div>
            <label for="firstname" class="form-label"
              >{{ translated.form.firstname }}:</label
            >
            <div class="mb-4 input-group">
              <span class="input-group-text">
                <i class="bi bi-person-fill text-secondary"></i>
              </span>
              <input
                type="text"
                id="firstname"
                class="form-control"
                v-model="form.firstname"
                required
              />
              <!-- tooltip -->
              <span class="input-group-text">
                <span
                  class="tt"
                  data-bs-placement="bottom"
                  title="Enter your first name"
                >
                  <i class="bi bi-question-circle text-muted"></i>
                </span>
              </span>
            </div>
            <label for="lastname" class="form-label"
              >{{ translated.form.lastname }}:</label
            >
            <div class="mb-4 input-group">
              <span class="input-group-text">
                <i class="bi bi-person-fill text-secondary"></i>
              </span>
              <input
                type="text"
                id="lastname"
                class="form-control"
                v-model="form.lastname"
                required
              />
              <!-- tooltip -->
              <span class="input-group-text">
                <span
                  class="tt"
                  data-bs-placement="bottom"
                  title="Enter your last name"
                >
                  <i class="bi bi-question-circle text-muted"></i>
                </span>
              </span>
            </div>
            <label for="language" class="form-label"
              >{{ translated.form.language }}:</label
            >
            <div class="input-group mb-4">
              <span class="input-group-text">
                <i class="bi bi-translate text-secondary"></i>
              </span>
              <select
                class="form-select"
                aria-label="Language"
                id="language"
                v-model="form.language"
                required
              >
                <option
                  v-for="lang in languages"
                  :value="lang.code"
                  :key="lang.code"
                >
                  {{ lang.locale }}
                  <span v-if="lang.locale != lang.localeInLanguage"
                    >- {{ lang.localeInLanguage }}</span
                  >
                </option>
              </select>
            </div>
            <div v-if="form.email.split('@')[1] != 'google.com'">
              <label for="type" class="form-label">{{
                translated.form.trainerOrPartner
              }}</label>
              <div class="mb-4 input-group">
                <span class="input-group-text">
                  <i class="bi bi-menu-button-wide-fill text-secondary"></i>
                </span>
                <select
                  class="form-select"
                  id="type"
                  v-model="form.type"
                  required
                >
                  <option value="GfE Champion" selected>GfE Champion</option>
                  <option value="Sales partner">Sales partner</option>
                  <option value="Professional development partner">
                    Professional development partner
                  </option>
                  <option value="Build partner">Build partner</option>
                  <option value="Tech partner">Tech partner</option>
                </select>
              </div>
            </div>

            <div v-if="form.type != 'GfE Champion'">
              <label for="company" class="form-label"
                >{{ translated.form.company }}:</label
              >
              <div class="mb-4 input-group">
                <span class="input-group-text">
                  <i class="bi bi-building text-secondary"></i>
                </span>
                <input
                  type="text"
                  id="company"
                  class="form-control"
                  v-model="form.company"
                  required
                />
                <!-- tooltip -->
                <span class="input-group-text">
                  <span
                    class="tt"
                    data-bs-placement="bottom"
                    title="Please enter the name of the Google Partner you work for."
                  >
                    <i class="bi bi-question-circle text-muted"></i>
                  </span>
                </span>
              </div>
            </div>
            <label class="form-label">{{ translated.form.agreement }}</label>
            <div class="mb-4 form-check">
              <input
                type="checkbox"
                id="emailOptIn"
                class="form-check-input"
                v-model="form.emailOptIn"
              />
              <label for="emailOptIn" class="form-check-label">{{
                translated.form.optIn
              }}</label>
            </div>

            <div class="mb-4 form-check">
              <input
                type="checkbox"
                id="agreement"
                class="form-check-input"
                v-model="form.agreement"
              />
              <label for="agreement" class="form-check-label">{{
                translated.form.agreementText
              }}</label>
            </div>
            <div
              class="mb-4 text-center"
              v-if="form.agreement && !form.processing"
            >
              <button type="submit" class="btn btn-primary">
                {{ translated.form.submit }}
              </button>
            </div>
            <div class="mb-4 text-center" v-if="!form.agreement">
              <button type="submit" class="btn btn-primary" disabled>
                {{ translated.form.submit }}
              </button>
            </div>
            <div class="mb-4 text-center" v-if="form.processing">
              <button class="btn btn-primary" type="button" disabled>
                <span
                  class="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Processing...
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="container-lg" v-if="form.submit == true">
      <div class="text-center">
        <h2>{{ translated.title }}</h2>
        <p class="lead">{{ translated.thankyou }}</p>
      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="emailVerificationModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Email Verification
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            {{ translated.checkEmail }}
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Email Already Exists Modal -->
    <div
      class="modal fade"
      id="emailExistsModal"
      tabindex="-1"
      aria-labelledby="emailExistsLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="emailExistsLabel">
              Email Already Registered
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            The email you provided is already registered. 
            You can try <a href="https://docs.google.com/forms/d/e/1FAIpQLSey74bz4V2O_oRwo0Ogw5aTW5llBt9OdQucAtKDLGj6J8GVWw/viewform" target="_blank">resetting your password</a>. 
            Alternatively, <a href="mailto:help@demogfe.com">contact support</a> for assistance.
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from "vue";
import { computed } from "@vue/reactivity";
import { Tooltip, Modal } from "bootstrap";
import axios from "axios";
import translate from "../composables/translate";
// firebase imports
import { db } from "../firebase/config";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();
    const { getLanguage, translated } = translate();
    const lang = computed(() => store.state.lang);
    getLanguage("registration", lang);
    console.log(navigator);
    const form = ref({
      email: "",
      firstname: "",
      lastname: "",
      type: "GfE Champion",
      company: "",
      agreement: false,
      emailOptIn: false,
      language: ref(
        navigator.language.substring(0, 2) ||
          navigator.userLanguage.substring(0, 2)
      ),
    });

    const verifyEmail = async (email) => {
      const url = `https://script.google.com/macros/s/AKfycbyKletQDU_nycy0EWH4cnXU6SoUu8VAVgRCzXHl3lES43aFXVzhChsMXbELpxXd4hmm/exec?email=${email}`;

      try {
        const response = await fetch(url, {
          redirect: "follow",
          method: "GET",
          headers: {
            "Content-Type": "text/plain",
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        return data.isValid;
      } catch (error) {
        console.log(
          "There was a problem with the fetch operation:",
          error.message
        );
        return false;
      }
    };

    const checkEmailExists = async (emailToCheck) => {
      const url =
        "https://us-central1-demotool-e1a5e.cloudfunctions.net/checkEmailExists";

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: emailToCheck }),
        });

        const data = await response.json();

        return data.exists;
      } catch (error) {
        console.error("Error checking email:", error);
        throw error;
      }
    };

    const handleGooglers = () => {
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (form.value.email.split("@")[1] === "google.com") {
        form.value.type = "Google";
        form.value.company = "Google";
        console.log("Google");
      } else {
        form.value.company = "";
      }
    };

    // const email = ref('')
    // const firstname = ref('')
    // const lastname = ref('')
    // const type = ref('')
    // const company = ref('')
    // const agreement = ref(false)
    // const language = ref(navigator.language.substring(0,2) || navigator.userLanguage.substring(0,2))

    const handleSubmit = async () => {
    form.value.processing = true;

    try {
        const emailExists = await checkEmailExists(form.value.email);

        if (emailExists) {
            form.value.processing = false;
            console.log("Email exists.");
            let emailExistsModal = new Modal(
                document.getElementById("emailExistsModal")
            );
            emailExistsModal.show();
            return;
        } else {
            console.log("Email does not exist.");
        }

        const isAllowed = (email) => {
            const emailParts = email.split('@');
            const emailDomain = emailParts[1];  

            return allowedEmails.includes(email) || allowedDomains.includes(emailDomain); 
        }

        // Array to store allowed email addresses
        const allowedEmails = [
            "peterghorner@gmail.com","juliaperdsjo@gmail.com","fridaheerman@gmail.com","elizabethsproat@gmail.com"
        ];

        const allowedDomains = [
            "workst.com.br","undervisningsdesign.com","onlitest.it","doit-hellas.com","rechargeables.co.za","wavenet.co.uk","har-per.com","rockalingua.com"
        ];

        

        const isEmailValid = await verifyEmail(form.value.email);
        console.log("verifyEmail", isEmailValid);

        if (!isEmailValid && !isAllowed(form.value.email)) {
                    console.log("email not verified");
                    form.value.processing = false;

                    // Show the modal when email is not verified
                    let modal = new Modal(
                        document.getElementById("emailVerificationModal")
                    );
                    modal.show();

                    return;
                  }


        const colRef = collection(db, "registration");
        await addDoc(colRef, {
        email: form.value.email,
        firstname: form.value.firstname,
        lastname: form.value.lastname,
        type: form.value.type,
        company: form.value.company,
        agreement: form.value.agreement,
        emailOptIn: form.value.emailOptIn,
        createdAt: serverTimestamp(),
        language: form.value.language,
        toolEmail: "",
        firstPassword: "",
        orgPath: "",
        license: "",
        userDomain: form.value.email.split("@")[1],
        lastLogin: "",
        userId: "",
        meetStatus: "",
        demoDomain: "demogfe.com",
      });

      // reset form
      form.value = {
        email: "",
        firstname: "",
        lastname: "",
        type: "",
        company: "",
        agreement: false,
        language: ref(
          navigator.language.substring(0, 2) ||
            navigator.userLanguage.substring(0, 2)
        ),
        submit: true,
        processing: false,
      };
    } catch (error) {
        console.error("An error occurred:", error);
    }
    };
    

    const languages = ref([]);
    axios.get("/data/locale.json").then((res) => {
      languages.value = res.data.languages;
    });

    return { handleSubmit, form, languages, translated, handleGooglers };
  },
  mounted() {
    const tooltips = document.querySelectorAll(".tt");
    tooltips.forEach((t) => {
      new Tooltip(t);
    });
  },
  updated() {
    const tooltips = document.querySelectorAll(".tt");
    tooltips.forEach((t) => {
      new Tooltip(t);
    });
  },
};
</script>

<style>
</style>