import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// styles
import 'bootstrap/dist/css/bootstrap.min.css'
import '@/assets/main.css'
import 'bootstrap'
import "bootstrap-icons/font/bootstrap-icons.css";
import '@/assets/fonts.css'


// firebase imports
import { auth } from '@/firebase/config'
import { onAuthStateChanged  } from 'firebase/auth'

//import store
import store from './store/index'

// sanatize html
import VueDOMPurifyHTML from 'vue-dompurify-html';

// tooltips
import tooltip from "@/composables/tooltip.js";
import "@/assets/tooltip.css";

const DOMPurifyConf = { default: {ADD_TAGS: ["iframe"], ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling', 'target']}  }


let app

onAuthStateChanged(auth, ()=> {
    if(!app) {
        app = createApp(App)
        .use(router)
        .use(store)
        .use(VueDOMPurifyHTML,DOMPurifyConf)
        .directive("tooltip", tooltip)
        .mount('#app')
    }
})

