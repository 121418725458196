<template>
<div :key="lang">
  <!-- Error -->
  <section class=" mt-3" v-if="!user && props.errorType">
    <div class="px-4 py-5 my-5 text-center">
    <h1 class="display-5">{{ props.errorType }}</h1>
    <div class="col-lg-6 mx-auto">
      <p class="lead mb-4">{{ props.errorMessage }}</p>
      
    </div>
  </div>
  </section>
  <!-- Before login -->
  <section id="intro" class="bg-light" v-if="!user && !props.errorType">
    <div class="px-4 py-5 my-5 text-center">
    <h1 class="display-4">{{ translated.newUserTitle }}</h1>
    <div class="mx-auto">
      <p class="lead mb-4">{{ translated.newUserMessage }}</p>
      <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
        <router-link :to="{ name: 'Register' }" class="btn btn-primary btn-lg px-4 gap-3">{{ translated.button.register}}</router-link>
        <button @click="handleLogin" class="btn btn-lg px-4 gap-3 btn-outline-primary">{{ translated.button.login }}</button>
      </div>
    </div>
  </div>
  </section>
  <section class="mt-5" v-if="!user && !props.errorType">
    <div class="container-lg">
      <h3 class="display-6">{{ translated.supportTitle }}</h3>
      <p>{{ translated.supportText}} <a href="mailto:help@demogfe.com">help@demogfe.com</a>.</p>
      <br/>
      
    </div> 
    <div class="toast-container position-absolute bottom-0 end-0 me-3 mb-2">
      <!-- <div ref="el2" class="toast" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="false">
    <div class="toast-body">The Demo Creation Tool has had an update! To get started login with your demoworkspaceedu.com account.
      <div class="mt-2 pt-2 border-top">
      
      
      <button type="button" class="btn btn-primary btn-sm me-2" data-bs-dismiss="toast">Got it</button>
      <a href="https://sites.google.com/demoworkspaceedu.com/home" target="_blank" class="btn btn-secondary btn-sm">Back to old demo tool</a>
    </div>
    </div>
  </div> -->
    <div ref="el" class="toast" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="false">
    <!-- <div class="toast-header"> -->
      <!-- <strong class="me-auto">Bootstrap</strong> -->
      <!-- <small class="text-muted">just now</small> -->
      <!-- <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div> -->
    <div class="toast-body">This site uses cookies from Google to deliver its services and to analyze traffic. Information about your use of this site is shared with Google. By using this site, you agree to its use of cookies.
      <div class="mt-2 pt-2 border-top">
      <button type="button" class="btn btn-primary btn-sm me-2" data-bs-dismiss="toast">Got it</button>
      <a href="https://policies.google.com/technologies/cookies" target="_blank" class="btn btn-secondary btn-sm">Learn more</a>
      
    </div>
    </div>
  </div>
    </div>
     
  </section>
  <!-- After login -->
  <section id="intro" class="mt-3" v-if="user">
    <div class="container-lg">
      
      
  <div class="row flex-lg-row-reverse align-items-center g-5 py-2">
      <div class="col-10 col-sm-8 col-lg-6">
        <div class="tutorial container text-center my-5 ratio ratio-4x3">
        <iframe src="https://www.youtube.com/embed/8XVZzkf5MBw"  allowfullscreen></iframe>
        </div>
      </div>
      <div class="col-lg-6">
        <h1 class="display-6">{{ translated.title }}</h1>
    
      <p class="lead mb-4">{{ translated.welcome }}</p>
      <div class="d-grid gap-2 d-sm-flex ">
        <a href="#createDemo" class="btn px-4 gap-3 btn-primary">{{ translated.button.createDemo}}</a>
        <router-link :to="{ name: 'Explore' }" class="btn px-4 gap-3 btn-outline-primary">{{ translated.button.explore }}</router-link>
      </div>
    
      </div>
    </div>




    </div>
  </section>
  <section v-if="user">
    <div class="container-lg mt-4">
      <div class="row">
        <div class="col-3 text-center">
        <h2><i class="bi bi-google" id="icon"></i></h2>
        <h4 class="display-7">{{translated.features.title1}}</h4>
        <p class="text-secondary">{{translated.features.text1}}</p>
      </div>
      <div class="col-3 text-center">
        <h2><i class="bi bi-person-check-fill" id="icon"></i></h2>
        <h4 class="display-7">{{translated.features.title2}}</h4>
        <p class="text-secondary">{{translated.features.text2}}</p>
      </div>
      <div class="col-3 text-center">
        <h2><i class="bi bi-puzzle-fill" id="icon"></i></h2>
        <h4 class="display-7">{{translated.features.title3}}</h4>
        <p class="text-secondary">{{translated.features.text3}}</p>
      </div>
      <div class="col-3 text-center">
        <h2><i class="bi bi-shield-lock" id="icon"></i></h2>
        <h4 class="display-7">{{translated.features.title4}}</h4>
        <p class="text-secondary">{{translated.features.text4}}</p>
      </div>
      </div>
    </div>  
  </section>
  <section id="createDemo" class="mt-5 bg-light" v-if="user">
    <div class="container-lg">
      <div class="row">
        <div class="col-9">
      <h2 class="display-5">{{ translated.createDemoTitle }}</h2>
    <p>{{ translated.createDemoText }}</p>
    </div>
    <div class="col-3">
      <div class="row d-flex align-items-center">
        <div class="col-4">
            <p class="text-end">Sort by</p>
      </div>
      <div class="col-8">
        <select class="form-select form-select-lg mb-3 me-3" id="sort" aria-label=".form-select-lg sort"
        @change="sortBy(sortType)" v-model="sortType">
        <option value="mostPopular">Most popular</option>
        <option value="createdAt">Recently added</option>
        <option value="AZ">A-Z</option>
        <option value="ZA">Z-A</option>
      </select>
      </div>
      </div>
      
      
    </div>
      </div>
    
    <div class="row">
        <div class="col-md-3">
      <div class="position-sticky" style="top: 4rem;">
          <div class="p-4 mb-3 rounded">
          <h4><i class="bi bi-filter"></i> {{ translated.filterTitle }}</h4>
          <form>
              <div v-for="form in forms" :key="form.title">
                  <div class="d-grid gap-2 border-top border-bottom p-1 mb-2">
                  <a class="link-dark text-decoration-none fs-5" type="button" data-bs-toggle="collapse" :data-bs-target="'#'+form.title.replace(/\s+/g, '-').toLowerCase()"
              role="button" aria-expanded="false" :aria-controls="form.title.replace(/\s+/g, '-').toLowerCase()"><i class="bi bi-chevron-down"></i> {{ form.title }}</a>
              </div>
              <div v-for="field in form.fields" :key="field.value">
                    <div class="collapse" :id="form.title.replace(/\s+/g, '-').toLowerCase()"  >
                  <div class="form-check">
                      <check-box :fieldId="field.name" v-model:tags="tagsArray" :value="field.value" />
                  </div>
                  </div>
                </div>
            
              </div>
        </form>
          </div>
      </div>
    </div>
    <div class="col-9">
        <div class="row my-5 align-items-left justify-content-left" >
  <div class="d-flex col-auto" v-for="demo in demosWithTags" :key="demo.id">
      <div class="card border-1 mb-4 shadow-sm" style="width: 18rem;">
            <div class="card-body text-center py-4 d-flex flex-column">
              <h6><span class="badge bg-secondary" v-if="demo.visibility == 'private'">Private</span><span class="badge bg-secondary" v-if="demo.visibility == 'shared'">Shared</span></h6>
      <h4 class="card-title">{{ demo.title[userInfo.document.language] || demo.title['en'] }}</h4>
      <!-- <div class="card-text mx-5 text-muted d-none d-lg-block" v-dompurify-html="demo.description.substring(0,Math.min(100, demo.description.substring(0,100).lastIndexOf(' ')))+'...'"></div> -->
      <p class="card-text mx-5 text-muted d-none d-sm-block" v-if="demo.description[userInfo.document.language]">{{ demo.description[userInfo.document.language].substring(0,Math.min(100, demo.description[userInfo.document.language].substring(0,100).lastIndexOf(' ')))+'...' }}</p>
      <p class="card-text mx-5 text-muted d-none d-sm-block" v-else-if="demo && demo.description && demo.description['en'] && typeof demo.description['en'] === 'string'">{{ demo.description['en'].substring(0,Math.min(100, demo.description['en'].substring(0,100).lastIndexOf(' ')))+'...' }}</p>
            
            <router-link :to="{ name: 'DemoDetails', params: { id: demo.id}}" class="btn btn-outline-primary border-0 stretched-link mt-auto">{{translated.button.chooseDemo}} <i class="bi bi-arrow-right"></i></router-link>
            </div>
      </div>
  </div>
 </div>
 
    </div>
  
    </div>
    
</div> 
  </section>
  <section class="mt-5" v-if="user">
    <div class="container-lg">
    <h2 class="display-5">{{ translated.additionalResources }}</h2>
    <div class="row">
      <div class="col-md-6">
        <h4>Slide decks</h4>
        <ul class="icon-list">
          <li><a href="https://docs.google.com/presentation/d/1nJAZYHrAe-K0OOqZ3HA1-YrY6aNO5yOIV5MosOkaIOU/edit5" rel="noopener" target="_blank">What’s new in Google for Education</a></li>
          <li><a href="https://docs.google.com/presentation/d/1ZxQlEng6Hw-1fPfiR1LuY0NRlqpgYZIUhxTLcqfSmBk/edit#slide=id.gbdc2b235bf_1_2840" rel="noopener" target="_blank">40+ ways to use Google Workspace for Education paid editions</a></li>

        </ul>
      </div>

      <div class="col-md-6">
        <h4>Videos</h4>
        <ul class="icon-list">
          <li><a href="https://www.youtube.com/playlist?list=PLP7Bvyb3ap44AB9oQclvFrXTFsoZJyJs6">Google Workspace for Education Demo Series</a></li>
          <li><a href="https://edu.google.com/intl/ALL_uk/get-started/paid-workspace-admin-support/">Get started with the paid editions of Google Workspace for Education</a></li>
        </ul>
      </div>
    </div>
    </div>  
  </section>
</div>  
</template>

<script>
import { computed, ref, watchEffect } from '@vue/reactivity'
import getCollection from '../composables/getCollection2'
import getTags from '../composables/getTags'
import Checkbox from "../components/CheckBox.vue";
import getUser from '../composables/getUser'
import translate from '../composables/translate'
import useLogin from '../composables/useLogin'
import { useStore } from 'vuex'
import { Toast } from 'bootstrap'

// firebase imports
import { db } from '../firebase/config'
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore'

export default {
  name: 'Home',
  props: {
        errorType: String,
        errorMessage: String
    },
  setup(props) {

    let currentURL = window.location.href;
    console.log('currentUrl',currentURL);

    
    const { documents: tagsArray } = getTags('forms')
    const { documents: tags } = getTags('forms')
    const { user, userInfo } = getUser()
    const store = useStore()
    const lang = computed(() => store.state.lang)
    const { getLanguage, translated, forms } = translate()
    getLanguage('home',lang)

    const { login } = useLogin()
    const handleLogin = async () => {
      await login()
    }
    const sortType = ref('mostPopular')
    const sorted = ref(['count','desc'])
    const sortBy = ((value) => {
      if (value === 'mostPopular'){
        sorted.value = ['count','desc']
        getCollection('demos','',sorted.value)
      }
      if (value === 'createdAt'){
        sorted.value = ['createdAt','desc']
        getCollection('demos','',sorted.value)
      }
      if (value === 'AZ') {
        sorted.value = ['title','asc']
        getCollection('demos','',sorted.value)
      }
      if (value === 'ZA') {
        sorted.value = ['title','desc']
        getCollection('demos','',sorted.value)
      }
    })
    
    
    const { documents: demos } = getCollection('demos','',sorted.value)

    //  const demosWithTags = computed(() => {
    //      return demos.value.filter(d => tagsArray.value.every(item => d.tags.includes(item))
    //      && ((d.visibility === 'public') || (d.visibility === 'private' && d.createdByOrg === userInfo.value.document.userDomain))
    //      )
    //  })

    const demosWithTags = computed(() => {
  return demos.value.filter(d => {
    // Ensure all required tags are present
    const tagsMatch = tagsArray.value.every(item => d.tags.includes(item));

    // Handle visibility based on the 'visibility' field of each demo
    const createdByUser = d.createdBy === user.value.email;
    const sharedWithEmail = d.visibility === 'shared' && (d.sharedWith || []).includes(user.value.email);
const sharedWithDomain = d.visibility === 'shared' && (d.sharedWithDomain || []).includes(userInfo.value.document.userDomain); 


    const visible = d.visibility === 'public' || createdByUser || sharedWithEmail || sharedWithDomain;

    // Debugging
    if (!visible) {
      console.log('Demo not visible:', d.id, 'Visibility:', d.visibility, 'Shared With:', d.sharedWith, 'Shared With Domain:', d.sharedWithDomain);
    }

    return tagsMatch && visible;
  });
});

    console.log('demos',demosWithTags)

    return { demos, tagsArray, demosWithTags, user, translated, lang, handleLogin, forms, userInfo, props, sortBy, sortType }
},
components: {
    "check-box": Checkbox,
  },mounted() {
    try{
      this.toast = new Toast(this.$refs.el);
    this.toast.show();
    this.toast2 = new Toast(this.$refs.el2);
    this.toast2.show();
    }
    catch(e){console.log()}
  },
  updated() {
    try{
      this.toast = new Toast(this.$refs.el);
    this.toast.show();
    this.toast2 = new Toast(this.$refs.el2);
    this.toast2.show();
    }
    catch(e){ console.log()}
    
  }

}
</script>

<style>

.card:hover{
     transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
    -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

#icon {
  color: #0F9D58;
}

.icon-list {
  padding-left: 0;
  list-style: none;
}
.icon-list li {
  display: flex;
  align-items: flex-start;
  margin-bottom: .25rem;
}
.icon-list li::before {
  display: block;
  flex-shrink: 0;
  width: 1.5em;
  height: 1.5em;
  margin-right: .5rem;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23212529' viewBox='0 0 16 16'%3E%3Cpath d='M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z'/%3E%3C/svg%3E") no-repeat center center / 100% auto;
}
</style>