import { createRouter, createWebHistory } from 'vue-router'

// view components
import Home from '../views/Home.vue'
import Register from '../views/Register.vue'
import Help from '../views/Help.vue'
import DemoDetails from '../views/demos/DemoDetails.vue'
import ManageDemos from '../views/demos/ManageDemos.vue'
import CreateDemoContent from '../views/content/CreateDemoContent.vue'
import EditDemoContent from '../views/content/EditDemoContent.vue'
import CreateDemo from '../views/demos/CreateDemo.vue'
import ErrorPage from '../views/Error.vue'
import SessionDetails from '../views/demos/SessionDetails.vue'
import Explore from '../views/Explore.vue'
import Partners from '../views/Partners.vue'
import Maintenance from '../views/Maintenance.vue'

import { auth } from '../firebase/config'

const requireAuth = (to, from, next) => {
  let user = auth.currentUser
  if (!user) {
    // redirect
    next({ name: 'Home'})
  }
  else {
    next()
  }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    props: true,
    meta: {
      title: 'Google for Education - Demo Workspace',
      transition: 'slide-left'
    }
  },
  {
    path: '/demo/:id',
    name: 'DemoDetails',
    component: DemoDetails,
    beforeEnter: requireAuth,
    props: true,
    meta: {
      transition: 'slide-left'
    }
  },
  {
    path: '/demo/create/:id',
    name: 'CreateDemo',
    component: CreateDemo,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/demo/manage',
    name: 'ManageDemos',
    component: ManageDemos,
    beforeEnter: requireAuth,
  },
  {
    path: '/content/build/:id?',
    name: 'CreateDemoContent',
    component: CreateDemoContent,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/content/edit',
    name: 'EditDemoContent',
    component: EditDemoContent,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/help',
    name: 'Help',
    component: Help
  },
  {
    path: '/explore',
    name: 'Explore',
    component: Explore
  },
  {
    path: '/error',
    name: 'Error',
    component: ErrorPage,
    props: route => ({ errorType: route.query.errorType, errorMessage: route.query.errorMessage })
  },  
  {
    path: '/demo/session/:id',
    name: 'SessionDetails',
    component: SessionDetails,
    beforeEnter: requireAuth,
    props: true,
    meta: {
      transition: 'slide-left'
    }
  },
  {
    path: '/Add-ons',
    name: 'Partners',
    component: Partners
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: Maintenance
  }
]


const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (window.location.href.includes('demoworkspaceedu-old.com') && to.name !== 'Maintenance') {
    next({ name: 'Maintenance' });
  } else {
    next();
  }
});


export default router
