<template>
  <div>
    {{ hoursFormatted }}:{{ minutesFormatted }}:{{ secondsFormatted }}
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from 'vue';

export default {
  name: 'CountdownTimer',
  
  props: {
    startingMinutes: {
      type: Number,
      required: true
    }
  },
  
  setup(props) {
    const totalSeconds = ref(props.startingMinutes * 60);
    
    const hours = computed(() => Math.floor(totalSeconds.value / 3600));
    const minutes = computed(() => Math.floor((totalSeconds.value % 3600) / 60));
    const seconds = computed(() => totalSeconds.value % 60);

    const hoursFormatted = computed(() => hours.value.toString().padStart(2, '0'));
    const minutesFormatted = computed(() => minutes.value.toString().padStart(2, '0'));
    const secondsFormatted = computed(() => seconds.value.toString().padStart(2, '0'));

    const countdown = () => {
      if (totalSeconds.value > 0) {
        totalSeconds.value--;
      } else {
        stopTimer();
      }
    };

    let timer;

    const startTimer = () => {
      timer = setInterval(countdown, 1000);
    };

    const stopTimer = () => {
      clearInterval(timer);
    };

    onMounted(startTimer);
    onUnmounted(stopTimer);

    return {
      hoursFormatted,
      minutesFormatted,
      secondsFormatted
    };
  }
}
</script>

<style scoped>
/* Add any required styling here */
</style>
