<template>
  <section style="height: 42px; background-color:#e8f0fe;" v-if="user && banner">
      <div>
          <div>
              <div class="col-md-12 text-center position-relative">
                  <p>
                      <strong>
                      Maintenance: demoworkspaceedu.com will be down for maintenance on August 23rd. Existing accounts will be deactivated and you will be required to register again. Please check your email for more details.
                      </strong></p>
                      <div class="text-end position-absolute top-50 end-0 translate-middle">
                  <button class="btn" @click="handleClick()"><i class="bi bi-x-circle"></i></button>
              </div>
              </div>
              
          </div>
      </div>
  </section>
</template>

<script>
import { ref } from '@vue/reactivity'
import getUser from '@/composables/getUser'
export default {
    setup() {
        const banner = ref(true)
        const { user } = getUser()
        const handleClick = (()=> {
            banner.value = false
        })
        return { banner, handleClick, user }
    }

}
</script>

<style>

</style>