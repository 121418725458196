<template>
  
  <section id="createDemo" class="mt-5">
    <div class="container-lg">
      <div class="row">
        <div class="col-9">
      <h2 class="display-5">Demo Workspace Add-ons</h2>
    <p>Browse the list of add-ons and extensions available on demo workspace.</p>
    </div>
    <div class="col-3">
      <div class="row d-flex align-items-center">
        <div class="col-4">
            <p class="text-end">Sort by</p>
      </div>
      <div class="col-8">
        <select class="form-select form-select-lg mb-3 me-3" id="sort" aria-label=".form-select-lg sort"
        @change="sortBy(sortType)" v-model="sortType">
        <option value="createdAt">Recently added</option>
        <option value="AZ">A-Z</option>
        <option value="ZA">Z-A</option>
      </select>
      </div>
      </div>
      
      
    </div>
      </div>
    
    <div class="row">
        <div class="col-md-3">
      <div class="position-sticky" style="top: 4rem;">
          <div class="p-4 mb-3 rounded">
          <h4><i class="bi bi-filter"></i> Filter</h4>
          <form>
              <div v-for="form in partnerFilter" :key="form.title">
                  <div class="d-grid gap-2 border-top border-bottom p-1 mb-2">
                  <a class="link-dark text-decoration-none fs-5" type="button" data-bs-toggle="collapse" :data-bs-target="'#'+form.title.replace(/\s+/g, '-').toLowerCase()"
              role="button" aria-expanded="false" :aria-controls="form.title.replace(/\s+/g, '-').toLowerCase()"><i class="bi bi-chevron-down"></i> {{ form.title }}</a>
              </div>
              <div v-for="field in form.fields" :key="field.value">
                    <div class="collapse" :id="form.title.replace(/\s+/g, '-').toLowerCase()"  >
                  <div class="form-check">
                      <check-box :fieldId="field.name" v-model:tags="tagsArray" :value="field.value" />
                  </div>
                  </div>
                </div>
              </div>
        </form>
          </div>
      </div>
    </div>
    <div class="col-9">
        <div class="row my-5 align-items-left justify-content-left" >
  <div class="d-flex col-auto" v-for="partner in partnersWithTags" :key="partner.id">
      <div class="card border-1 mb-4 shadow-sm pt-1" style="width: 19rem;">
        <img :src="getImgUrl(partner.image)" class="card-img-top partnerLogo" alt="...">
            <div class="card-body text-center py-4 d-flex flex-column">
      <h4 class="card-title">{{ partner.title }}</h4>
      <!-- <div class="card-text mx-5 text-muted d-none d-lg-block" v-dompurify-html="demo.description.substring(0,Math.min(100, demo.description.substring(0,100).lastIndexOf(' ')))+'...'"></div> -->
      <!-- <p class="card-text mx-5 text-muted d-none d-sm-block" v-if="partner.description['en']">{{ partner.description['en'].substring(0,100).lastIndexOf(' ')+'...' }}</p> -->
      <p class="card-text mx-5 text-muted d-none d-sm-block">{{ partner.description['en'].substring(0,Math.min(100, partner.description['en'].substring(0,100).lastIndexOf(' ')))+'...' }}</p>
            <!-- <router-link :to="{ name: 'DemoDetails', params: { id: demo.id}}" class="btn btn-outline-primary border-0 stretched-link mt-auto">{{translated.button.chooseDemo}} <i class="bi bi-arrow-right"></i></router-link> -->
            </div>
      </div>
  </div>
 </div>
 
    </div>
  
    </div>
    
</div> 
  </section>
</template>

<script>
import translate from '@/composables/translate'
import { useStore } from 'vuex'
import getUser from '../composables/getUser'
import { computed, ref } from '@vue/reactivity'
import getCollection from '../composables/getCollection2'
import Checkbox from "../components/CheckBox.vue";

export default {
setup(){
  const { user, userInfo } = getUser()
  const store = useStore()
  const lang = computed(() => store.state.lang)
  const { documents: partners } = getCollection('partners')
  const { getLanguage, translated, partnerFilter } = translate()
  getLanguage('partners',lang)
  
  const sortBy = ((value) => {
      if (value === 'createdAt'){
        sorted.value = ['createdAt','desc']
        getCollection('partners','',sorted.value)
      }
      if (value === 'AZ') {
        sorted.value = ['title','asc']
        getCollection('partners','',sorted.value)
      }
      if (value === 'ZA') {
        sorted.value = ['title','desc']
        getCollection('partners','',sorted.value)
      }
    })

  const tagsArray = ref([])  

  const partnersWithTags = computed(() => {
         return partners.value.filter(d => tagsArray.value.every(item => d.tags.includes(item))
         )
     })


     function getImgUrl(icon) {
    return require('@/assets/icons/partners/'+icon+'.png')
    }

  return { partners, sortBy, partnerFilter, partnersWithTags, tagsArray, getImgUrl }
},
components: {
    "check-box": Checkbox,
  }
}
</script>

<style>
.partnerLogo {
  object-fit:scale-down;
  width: 100%;
  height: 250px;
}

.card:hover{
     transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
    -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

#icon {
  color: #0F9D58;
}

.icon-list {
  padding-left: 0;
  list-style: none;
}
.icon-list li {
  display: flex;
  align-items: flex-start;
  margin-bottom: .25rem;
}
.icon-list li::before {
  display: block;
  flex-shrink: 0;
  width: 1.5em;
  height: 1.5em;
  margin-right: .5rem;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23212529' viewBox='0 0 16 16'%3E%3Cpath d='M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z'/%3E%3C/svg%3E") no-repeat center center / 100% auto;
}
</style>